// Import dependencies
import React, { Component } from 'react';
import { connect } from "react-redux";
import ACTIONS from "../../../modules/action";


class InitiativeTracker extends Component {
    state = {nameInput: "", participants: []};

    componentDidMount = event => {
        let localInit = JSON.parse(localStorage.getItem("initiative"));
        if (localInit) {
            this.setState({participants: localInit});
        }
    }

    handleChange = (event) => {
        let cloneParticipants = this.state.participants.slice();
        cloneParticipants[event.target.name].initiative = event.target.value;
        this.setState({participants: cloneParticipants});
        this.saveToLocal();
    }

    handleModChange = (event) => {
        let cloneParticipants = this.state.participants.slice();
        cloneParticipants[event.target.name].modifier = event.target.value;
        this.setState({participants: cloneParticipants});
        this.saveToLocal();
    }

    renderTracker = () => {

        let turnOrder = this.state.participants.map((participant, index) =>
            <div className="ch-init-participant">
                <p className="ch-init-name"><b>{participant.name}:</b></p>
                <input type="number" value={participant.initiative} name={index} onChange={this.handleChange} />
                <input type="number" className="ch-init-modifier" value={participant.modifier} name={index} onChange={this.handleModChange} />
                <button name={index} onClick={this.randomRoll}>D20</button>
                <button name={index} onClick={this.deleteParticipant}>Delete</button>
            </div>
        );
        
        return turnOrder;
    }

    randomRoll = (event) => {
        let cloneParticipants = this.state.participants.slice();
        cloneParticipants[event.target.name].initiative = Math.floor(Math.random() * Math.floor(19)) + 1 + parseFloat(cloneParticipants[event.target.name].modifier);
        this.setState({participants: cloneParticipants});
        this.saveToLocal();
    }

    saveToLocal = () => {
        localStorage.setItem("initiative", JSON.stringify(this.state.participants));
    }

    deleteParticipant = (event) => {
        let cloneParticipants = this.state.participants.slice();
        cloneParticipants.splice(event.target.name, 1);
        this.setState({participants: cloneParticipants});
        this.saveToLocal();
    }

    sortTurnOrder = () => {
        let cloneParticipants = this.state.participants.slice();
        cloneParticipants.sort(function(a,b){
            return b.initiative - a.initiative
        });
        this.setState({participants: cloneParticipants});
        this.saveToLocal();
    }

    addParticipant = () => {
        if (this.state.nameInput.length > 0) {
            let cloneParticipants = this.state.participants.slice();
            cloneParticipants.push({name: this.state.nameInput, initiative: 0})
            this.setState({participants: cloneParticipants, nameInput: ""});
            this.props.editInitiative(cloneParticipants);
            this.saveToLocal();
        }
    }

    nameInputChange = (event) => {
        this.setState({nameInput: event.target.value});
    }

    render () {
        return (
            <div className="ch-init-tracker">
                <button onClick={this.sortTurnOrder}>Sort Initiatives</button>
                {this.renderTracker()}
                <div className="ch-init-add">
                    <p>Add Character</p>
                    <input type="text" value={this.state.nameInput} onChange={this.nameInputChange} />
                    <button onClick={this.addParticipant}>Create</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    initiative: state.initiative
});

const mapDispatchToProps = dispatch => ({
    editInitiative: task => dispatch(ACTIONS.editInitiative(task))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitiativeTracker);
