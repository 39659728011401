import React, { Component } from "react";
import { connect } from "react-redux";
import ACTIONS from "../../modules/action";
import { Storage } from "aws-amplify";
import fetchFiles from "../../libs/fetchFiles";

import MediaDisplay from "./MediaDisplay"


class MediaUploader extends Component {
    state = {images: [], audio: []}

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleFileSelect = event => {
        let file = null;
        // Let's verify that the user grabbed a file.
        if (event.target.files[0]) {
            file = event.target.files[0];
        }
        this.setState({
            file: file
        });
    }

    getS3Files = async (destination) => {
        // Fetch all images within the user's private folder. The path restrictions are defined in the IAM role for authorized users.
        let privateFiles = await fetchFiles(destination);
        for (var key in privateFiles) {
            if (destination === 'images') {
                this.props.createImage(privateFiles[key]);
            } else {
                this.props.createMusic(privateFiles[key]);
            }
        }
    }

    deleteS3File = async (event, destination) => {
        await Storage.remove(event.target.name, {level:'private'});

        if (destination === 'images') {
            await this.props.deleteImages();
            this.getS3Files('images');
        } else {
            await this.props.deleteMusic();
            this.getS3Files('audio');
        }
        
    }

    handleSubmit = async event => {

        // Cap the maximum amount of user uploads.
        if ((this.state.images.length + this.state.audio.length) > 39) {
            alert("Sorry. You can only upload a maximum of 40 files at this time.");
            return;
        }

        // validation
        if (!this.state.file) {
            alert("You still need to select a file.");
            return;
        } else if (this.state.file.size > (1048576 * 5)) {
            alert("Please select a file smaller than 5mb.");
            return;
        }

        let destination = 'images';

        // Check whether this is a music track or an image
        if (this.state.file.type.includes('audio')) {
            destination = 'audio';
        }

        try {
            await Storage.put(destination + '/' + this.state.file.name, this.state.file, {level: 'private'});
            if (destination === 'images') {
                await this.props.deleteImages();
                this.getS3Files('images');
            } else {
                await this.props.deleteMusic();
                this.getS3Files('audio');
            }
        } catch (e) {
            alert(e);
        }
        this.setState({
            file: null
        });
    }

    render() {
       return (
           <div className="app ch-media-uploader">
               <h1>Upload a file.</h1>
               <p>You have a max upload limit of 5mb <em>(40 total uploads)</em>.</p>
               <div className="ch-file-upload">
                    <label>
                        {this.state.file ? this.state.file.name : "Select a file..."}
                        <input type="file" name="file" id="file" onChange={this.handleFileSelect} accept=".jpg,.png,.mp3,.wav" />
                    </label>
               </div>
               <div>
                <button onClick={this.handleSubmit}>Upload File</button>
                </div>
               <hr/>
               <MediaDisplay handleClick={(e) => this.deleteS3File(e, "images")} buttonText="Delete" gallery={this.props.images} />
               <MediaDisplay audio={true} handleClick={(e) => this.deleteS3File(e, "audio")} buttonText="Delete" gallery={this.props.music} />
           </div>
       );
    }
}

const mapStateToProps = state => ({
    loggedIn: state.loggedIn,
    images: state.images,
    music: state.music
});

const mapDispatchToProps = dispatch => ({
    createImage: media => dispatch(ACTIONS.createImage(media)),
    createMusic: media => dispatch(ACTIONS.createMusic(media)),
    deleteImages: media => dispatch(ACTIONS.deleteImages(media)),
    deleteMusic: media => dispatch(ACTIONS.deleteMusic(media))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaUploader);