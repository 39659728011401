import React, { Component } from "react";
import ACTIONS from "../../modules/action";
import Modal from 'react-responsive-modal';
import { connect } from "react-redux";

//libs
import fetchFile from "../../libs/fetchFile";

import MediaDisplay from "../MediaUploader/MediaDisplay"

class JournalSearchWindow extends Component {
    state = {displaySuggestions: false, open: false, privateImages: null, journalImages: null, noMedia: false, hasSelectedMedia: false, fetchedJournalImages: []}

    // JOURNAL LOGIC //////////////////////////

    handleDelete = event => {
        // Check to make sure the user is serious.
        if (!window.confirm("Are you sure you want to delete this journal?")) {
            return;
        }
        // delete the journal from the store
        this.props.deleteJournal(event.target.name);
        // Wipe out the currently displayed journal and suggestions
        this.setState({
            displayedJournal: null,
            autocompleteSuggestions: null
        });
    };

    // MODAL LOGIC //////////////////////////

    onOpenModal = event => {
        this.setState({
            open: true
        });
        event.preventDefault();
    }

    onCloseModal = () => {
        this.setState({
            open: false
        });
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = () => {

        // validation
        if (!this.state.journalName || this.state.journalName.length < 2) {
            alert("Your journal's name must be longer than 2 characters.");
            return;
        } else if (!this.state.journalDescription) {
            alert("You journal won't help much if it doesn't have content.");
            return;
        }

        // create a new journal in the store
        let journal = {name: this.state.journalName, description: this.state.journalDescription, images: this.state.journalImages};
        this.props.createJournal(journal);
        // Clear out the states so we don't reuse these on accident
        this.setState({
            journalName: null,
            journalDescription: null,
            journalImages: null,
            hasSelectedMedia: false
        });
        this.onCloseModal();
    };

    // AUTOCOMPLETE LOGIC //////////////////////////

    displayAutocomplete = () => {
        this.setState({
            displaySuggestions: true
        });
    }

    handleAutocompleteSelection = event => {
        // Grab the journal using the ID passed in from the event click and set our component's state.
        let index = this.props.journals.findIndex(function (e) {return e.id === event.target.name});
        
        // Grab the images off the journal (if there is one) and fetch it from S3.
        if (this.props.journals[index].images) {
            for (let i = 0; i < this.props.journals[index].images.length; i++) {
                this.getJournalImage(this.props.journals[index].images[i].url);
            }
        }

        this.setState({
            displayedJournal: this.props.journals[index],
            displaySuggestions: false
        }, () => {console.log(this.state.displayedJournal)});

    }

    populateSuggestions = event => {
        // When the user enters a search string above our minimum string length, we check for matching journals.
        let matches = [];
        if (event.target.value.length > 1) {
            this.props.journals.forEach(function(item) {
                if (item.name.toLowerCase().includes(event.target.value.toLowerCase())) {
                    matches.push(item);
                }
            });
        }
        let suggestions = matches.map((suggestion, index) =>
            <button tabIndex={0} className="ch-autocomplete-suggestion" key={suggestion.id} name={suggestion.id} onClick={this.handleAutocompleteSelection}>{suggestion.name}</button>
        );
        this.setState({
            autocompleteSuggestions: suggestions
        });
    }

    // MEDIA DISPLAY LOGIC //////////////////////////

    getJournalImage = async (key) => {
        let fetchedJournalImage = await fetchFile(key, 'private');
        this.setState({
            fetchedJournalImages: this.state.fetchedJournalImages.concat(fetchedJournalImage)
        });
    }

    addMedia = () => {
        if (!this.state.hasSelectedMedia && this.props.images) {
            return (<MediaDisplay select="multiple" handleClick={(e) => this.selectGalleryImage(e)} buttonText="Select" gallery={this.props.images} />);
        } else {
            return (<p>{this.state.journalImage}</p>);
        }
    }

    selectGalleryImage = (selections) => {

        let selectedMedia = [];
        
        for (let i = 0; i < selections.length; i++) { 
            selectedMedia.push(selections[i]);
        }

        this.setState({
            journalImages: selectedMedia
            // locMusicSrc: selection,
            // locMusicName: selection.split('/')[1],
            //hasSelectedMedia: true
        }, () => {console.log(this.state.journalImages)})
    }

    render() {
        return (
            <div className="ch-journal-window">
                <h2>Your Journals <button className="ch-create-journal" type="button" onClick={this.onOpenModal}>Create a journal.</button></h2>
                <div className="ch-autocomplete-container">
                    <input autoComplete="off" placeholder="Search your journals..." onFocus={this.displayAutocomplete} name="autocomplete-query" type="text" onChange={this.populateSuggestions} />
                    <div className="ch-autocomplete-dropdown">
                        {this.state.displaySuggestions && this.state.autocompleteSuggestions}
                    </div>
                </div>
                {this.state.displayedJournal &&
                    <div className="ch-journal-content">
                        <h3>{this.state.displayedJournal.name}</h3>
                        <p>{this.state.displayedJournal.description}</p>
                        {this.state.displayedJournal.images && this.state.displayedJournal.images.map((image, index) =>
                            <img src={this.state.fetchedJournalImages[index]} alt="Invalid url." className="ch-journal-image" />
                        )}
                        {this.state.fetchedJournalImage && <img src={this.state.fetchedJournalImage} alt="Invalid url." className="ch-journal-image" />}
                        <button name={this.state.displayedJournal.id} onClick={this.handleDelete}>Delete this journal.</button>
                    </div>
                }
                <Modal classNames={{modal: {chModal: true}}} open={this.state.open} onClose={this.onCloseModal} center>
                    <h2>Create a new journal.</h2>
                    <p>Give your journal a name:</p>
                    <input autoComplete="off" type="text" name="journalName" onChange={this.handleChange} />
                    <p>Enter a few sentences describing your journal:</p>
                    <textarea name="journalDescription" onChange={this.handleChange} />
                    <p>Does your journal have an associated image?</p>
                    <div className="ch-media-display-container">
                        {this.addMedia()}
                    </div>
                    <div className="ch-submit-button">
                        <button onClick={this.handleSubmit}>Create Journal</button>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    journals: state.journals,
    images: state.images,
});

const mapDispatchToProps = dispatch => ({
    deleteJournal: id => dispatch(ACTIONS.deleteJournal(id)),
    createJournal: journal => dispatch(ACTIONS.createJournal(journal)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JournalSearchWindow);