import React, { Component } from "react";
import ACTIONS from "../../modules/action";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

class ChangePassword extends Component {
    state = {confirmed: false, codeSent: false}

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSendCodeSubmit = async event => {
        event.preventDefault();

        //validation
        if (!this.state.email) {
            alert("You must enter the email for your account.");
            return;
        }

        this.setState({ isSendingCode: true });

        try {
            await Auth.forgotPassword(this.state.email);
            this.setState({codeSent: true});
        } catch (e) {
            alert(e.message);
            this.setState({ isSendingCode: false });
        }
    }

    handleConfirmSubmit = async event => {
        event.preventDefault();

        //validation
        if (!this.state.confirmationCode) {
            alert("Please check your email for a confirmation code.");
            return;
        } else if (this.state.newPassword !== this.state.confirmNewPassword) {
            alert("You passwords do not match");
            return;
        }

        this.setState({ isConfirming: true });

        try {
            await Auth.forgotPasswordSubmit(
                this.state.email,
                this.state.confirmationCode,
                this.state.newPassword
            );
            this.setState({ confirmed: true });
        } catch (e) {
            alert(e.message);
            this.setState({ isConfirming: false });
        }
    }

    renderSendCodeForm = () => {
        return (
            <form onSubmit={this.handleSendCodeSubmit}>
                <h3>Forgot Your Password?</h3>
                <p>Enter your email. If the email is tied to an account, I will send a confirmation code to it.</p>
                <input type="email" name="email" onChange={this.handleChange} />
                <input type="submit" />
            </form>
        )
    }

    renderConfirmationForm = () => {
        return (
            <form onSubmit={this.handleConfirmSubmit}>
                <h3>Enter the confirmation code below.</h3>
                <input type="text" name="confirmationCode" />
                <br/>
                <hr/>
                <h3>Enter your desired password below.</h3>
                <input type="password" placeholder="Enter a new password..." name="newPassword" onChange={this.handleChange} />
                <input type="password" placeholder="Confirm your new password..."  name="confirmNewPassword" onChange={this.handleChange} />
                <input type="submit" />
            </form>
        )
    }

    renderSuccessMessage = () => {
        return (
            <div className="ch-success">
                    <h3>Your password has been successfully reset.</h3>
                    <p>Click <Link to="/login">here</Link> to login with your new credentials.</p>
            </div>
        )
    }

    render() {
        return (
        <div className="app ch-login-form ResetPassword">
            {!this.state.codeSent
            ? this.renderSendCodeForm()
            : !this.state.confirmed
                ? this.renderConfirmationForm()
                : this.renderSuccessMessage()}
        </div>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    editLogin: boolean => dispatch(ACTIONS.editLogin(boolean))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword);