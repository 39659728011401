import React, { Component } from "react";
import ACTIONS from "../../../modules/action";
import { connect } from "react-redux";

class Pin extends Component {
    state = {}

    handleSelect = () => {
        // Change the active location
        this.props.selectLocation(this.props.id);
    }

    handleDelete = event => {
        // Prevent the context menu from popping up
        event.preventDefault();
        // Check to make sure the user is serious.
        if (!window.confirm("Are you sure you want to delete this location?")) {
            return;
        }

        // If this is the active location, we need to update
        if (this.props.activeLocation && (this.props.activeLocation.id === this.props.id)) {
            this.props.selectLocation(null);
        }

        // Delete this location
        this.props.deleteLocation(this.props.id);
    }

    showTitle = () => {
        switch (this.props.filter) {
            case 0:
                return 100;
            case 1:
                if (this.props.isImportant) {
                    return 100;
                } else {
                    return 0;
                }
            default:
                return 0;
        }
    }

    render() {
        return (
            <div className="ch-pin" style={{left: this.props.position.x + "%", top: this.props.position.y + "%"}}>
                <img onClick={this.handleSelect} alt="pin" onContextMenu={this.handleDelete} src={ this.props.isImportant ? this.props.redPin : this.props.whitePin} />
                <div onClick={this.handleSelect} className="ch-pin-title" style={{opacity: this.showTitle()}}>{this.props.name}</div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    activeMap: state.activeMap,
    activeLocation: state.activeLocation
});

const mapDispatchToProps = dispatch => ({
    selectLocation: id => dispatch(ACTIONS.selectLocation(id)),
    deleteLocation: id => dispatch(ACTIONS.deleteLocation(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Pin);