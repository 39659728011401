import { Storage } from "aws-amplify";

async function fetchFiles (destination) {
    // Fetch all images within the user's private folder. The path restrictions are defined in the IAM role for authorized users.
    let imageKeyList = await Storage.list(destination, {level: 'private'});

    let privateImages = [];
    for (var key in imageKeyList) {
        let item = await Storage.get(imageKeyList[key].key, {level: 'private'});
        let mediaObject = {url: item, name: imageKeyList[key].key}
        
        privateImages[key] = mediaObject;
    }
    
    return privateImages;
}

export default fetchFiles;