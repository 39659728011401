import React, { Component } from "react";
import ACTIONS from "../../modules/action";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import Recaptcha from "react-recaptcha";

class Register extends Component {
    state = {loggedIn: false, newUser: null, recaptcha: false}

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        if (!this.state.recaptcha) {
            alert("You must pass the Recaptcha before submitting.");
            return;
        }

        // Validation
        switch(this.state.password) {
            case null:
                alert("Please enter a valid password.");
                return;
            case !this.state.confirmPassword:
                alert("Make sure your password matches.");
                return;
            default:
                if (!this.state.password || this.state.password.length < 6) {
                    alert("Make sure your password is over 6 characters.");
                    return;
                }
                if (!this.state.email) {
                    alert("Please enter a valid email.");
                    return;
                }
        }

        this.setState({ isLoading: true });

        try {
            const newUser = await Auth.signUp({
            username: this.state.email,
            password: this.state.password
            });
            this.setState({
            newUser
            });
        } catch (e) {
            alert(e.message);
        }

        this.setState({ isLoading: false });
    }

    handleConfirmationSubmit = async event => {
        event.preventDefault();

        // Validation
        if (!this.state.confirmationCode) {
            alert("You didn't enter a confirmation code. Please check your email.");
            return;
        }

        this.setState({ isLoading: true });

        try {
            await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
            await Auth.signIn(this.state.email, this.state.password);

            this.props.editLogin(true);
            this.props.history.push("/");
        } catch (e) {
            alert(e.message);
            this.setState({ isLoading: false });
        }
    }

    verifyRecaptcha = () => {
        this.setState({
            recaptcha: true
        })
    }

    renderForm = () => {
        return (
            <form onSubmit={this.handleSubmit}>
                <h2>Register</h2>
                <p>Please enter an email address and a password for your account.</p>
                <p>Password rules:</p>
                <ul>
                    <li>7 or more characters.</li>
                    <li>Contains uppercase and lowercase characters.</li>
                    <li>Contains at least one numeric character.</li>
                    <li>Contains at least one special symbol.</li>
                </ul>
                <input placeholder="Enter your email..." type="email" name="email" onChange={this.handleChange} />
                <input placeholder="Enter a password..." type="password" name="password" onChange={this.handleChange} />
                <input placeholder="Confirm your password..." type="password" name="confirmPassword" onChange={this.handleChange} />
                <Recaptcha size="normal" verifyCallback={this.verifyRecaptcha} sitekey="6LfH_qsUAAAAAPgO_rglWZZRN32F2r8gBGKWZHWI" />
                <input className="ch-login-submit" type="submit" value="Register" />
            </form>
        )
    }

    renderConfirmationForm = () => {
        return (
            <form onSubmit={this.handleConfirmationSubmit}>
                <h2>Confirm Your Account</h2>
                <p>Please go to your email in a new tab and find your confirmation code. Enter it in the input below.</p>
                <input placeholder="Confirm your account..." type="text" name="confirmationCode" onChange={this.handleChange} />
                <input className="ch-login-submit" type="submit" value="Confirm" />
            </form>
        )
    }

    render() {
        return (
            <div className="ch-login-form">
                {this.state.newUser === null
                    ? this.renderForm()
                    : this.renderConfirmationForm()}
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    editLogin: boolean => dispatch(ACTIONS.editLogin(boolean))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Register);