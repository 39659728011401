import React, { Component } from "react";
import Modal from 'react-responsive-modal';
import ACTIONS from "../../../modules/action";
import { connect } from "react-redux";
import Pin from "./Pin";
import { Storage } from "aws-amplify";

import fetchFile from "../../../libs/fetchFile";

import MediaDisplay from "../../MediaUploader/MediaDisplay"

class MapWindow extends Component {

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
    }

    state = {
        zoomAmount: 100,
        open: false,
        filter: 0,
        mapImg: null,
        fetchedMaps: [],
        privateAudio: [],
        noMedia: false,
        hasSelectedMedia: false
    }

    componentDidMount = event => {
        this.getZoomIcons();
        
        // If we have an activeMap to begin, grab the image.
        if (this.props.activeMap) {
            this.setState({fetchedMapUrl: null});
            this.getMapImage(this.props.activeMap.url);
        }
    }

    // MAP LOGIC ////////////////////////////////////////

    // This is the recommended approach for async http requests.
    componentDidUpdate(prevProps) {
        if (this.props.activeMap && (!prevProps.activeMap || this.props.activeMap.url !== prevProps.activeMap.url)) {
            this.setState({fetchedMapUrl: null});
            this.getMapImage(this.props.activeMap.url);
        }
    }

    getMapImage = async (key) => {
        let level = 'private';

        // Determine if we are still using the example map from the public bin.
        if (key === 'ch-example-map.jpg') {
            level = 'public';
        }

        let fetchedMapUrl = await fetchFile(key, level);
        this.setState({
            fetchedMapUrl: fetchedMapUrl
        });
    }

    renderPins = () => {
        let pins = this.props.activeMap.locations.map((location) =>
            <Pin redPin={this.state.redPin} key={location.id} whitePin={this.state.whitePin} id={location.id} isImportant={location.important} name={location.name} filter={this.state.filter} position={{x: location.position.x , y: location.position.y}} />
        );
        return pins;
    }

    // ZOOM CONTAINER LOGIC ////////////////////////////////////////

    getZoomIcons = async event => {
        let redPin = await Storage.get('mapPinRed.png', {level: 'public'});
        let whitePin = await Storage.get('mapPinWhite.png', {level: 'public'});
        let bothPin = await Storage.get('mapPinBoth.png', {level: 'public'});

        this.setState({
            redPin: redPin,
            whitePin: whitePin,
            bothPin: bothPin
        });
    }

    zoom = event => {
        let amount = parseInt(event.target.name);
        this.setState({
            zoomAmount: this.state.zoomAmount + amount
        });
    }

    toggleFilter = () => {
        if (this.state.filter >= 2) {
            this.setState({
                filter: 0
            });
        } else {
            this.setState({
                filter: this.state.filter + 1
            });
        }
    }

    renderFilterSymbol = () => {
        switch(this.state.filter) {
        case 0:
            return <img alt="Both pin filter." src={this.state.bothPin} />
        case 1:
            return <img alt="Red pin filter." src={this.state.redPin} />
        default:
            return <span>&#8416;</span>
        }
    }

    // MODAL LOGIC ////////////////////////////////////////

    onOpenModal = event => {
        this.setState({
            open: true,
            locPosition: {x: ((event.nativeEvent.offsetX - 12) / event.target.offsetWidth) * 100, y:  ((event.nativeEvent.offsetY - 24) / event.target.offsetHeight) * 100}
        });
        event.preventDefault();
    }

    onCloseModal = () => {
        this.setState({
            open: false
        });
    }

    handleChecked = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    }

    handleSubmit = () => {

        // validation
        if (!this.state.locName) {
            alert("Your location requires a name.");
            return;
        }

        //create the music objects
        // let musicObj = null;
        // if (this.state.locMusicName && this.state.locMusicSrc) {
        //     musicObj = [{name: this.state.locMusicName, url: this.state.locMusicSrc}];
        // }

        // create a new location in the store
        let location = {name: this.state.locName, position: {x: this.state.locPosition.x, y: this.state.locPosition.y}, description: this.state.locDescription, important: this.state.locImportant, music: this.state.locMusic};
        this.props.createLocation(location);
        
        // Clear out the states so we don't reuse these on accident
        this.setState({
            locName: null,
            locPosition: null,
            locDescription: null,
            locImportant: null,
            locMusicSrc: null,
            locMusicName: null,
            locMusic: null,
            hasSelectedMedia: false
        });
        this.onCloseModal();
    };


    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    addMedia = () => {
        if (!this.state.hasSelectedMedia && this.props.music) {
            return (<MediaDisplay select="multiple" audio={true} handleClick={(e) => this.selectGalleryAudio(e)} buttonText="Select" gallery={this.props.music} />);
        } else {
            return (<p>{this.state.locMusicName}</p>);
        }
    }

    selectGalleryAudio = (selections) => {

        let selectedMedia = [];
        
        for (let i = 0; i < selections.length; i++) { 
            selectedMedia.push(selections[i]);
        }

        this.setState({
            locMusic: selectedMedia
            // locMusicSrc: selection,
            // locMusicName: selection.split('/')[1],
            //hasSelectedMedia: true
        }, () => {console.log(this.state.locMusic)})
    }

    render() {

        return (
            <div className="ch-map-window-container">
                <div className="ch-map-window">
                    <div className="ch-map-zoom-adjust" style={{ width: this.state.zoomAmount + "%" }}>
                        <img ref={this.mapRef} alt="Select a map." onContextMenu={this.onOpenModal} src={this.state.fetchedMapUrl} />
                        {this.props.activeMap && this.renderPins()}
                    </div>
                </div>
                {this.props.activeMap && <div className="ch-zoom-container">
                    <button onClick={this.zoom} name="10" className="ch-zoom-button">+</button>
                    <button onClick={this.toggleFilter} className="ch-zoom-button">{this.renderFilterSymbol()}</button>
                    <button onClick={this.zoom} name="-10" className="ch-zoom-button">&#8722;</button>
                </div>}
                <Modal classNames={{modal: {chModal: true}}} open={this.state.open} onClose={this.onCloseModal} center>
                    <h2>Create a new location.</h2>
                    <p>Give your location a name:</p>
                    <input autoComplete="off" type="text" name="locName" onChange={this.handleChange} />
                    <p>Enter a few sentences describing your location:</p>
                    <textarea name="locDescription" onChange={this.handleChange} />
                    <p>Select music for this location:</p>
                    <div className="ch-media-display-container">
                        {this.addMedia()}
                    </div>
                    <div className="ch-important-input">
                        <label>Important?</label>
                        <input type="checkbox" name="locImportant" onChange={this.handleChecked} />
                    </div>
                    <div className="ch-submit-button">
                        <button onClick={this.handleSubmit}>Create Location</button>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    activeMap: state.activeMap,
    maps: state.maps,
    music: state.music
});

const mapDispatchToProps = dispatch => ({
    createLocation: journal => dispatch(ACTIONS.createLocation(journal))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapWindow);