// types of action
const Types = {
  CREATE_LOCATION: "CREATE_LOCATION",
  DELETE_LOCATION: "DELETE_LOCATION",
  EDIT_LOCATION: "EDIT_LOCATION",
  SELECT_LOCATION: "SELECT_LOCATION",
  CREATE_MAP: "CREATE_MAP",
  DELETE_MAP: "DELETE_MAP",
  EDIT_MAP: "EDIT_MAP",
  SELECT_MAP: "SELECT_MAP",
  CREATE_JOURNAL: "CREATE_JOURNAL",
  DELETE_JOURNAL: "DELETE_JOURNAL",
  EDIT_JOURNAL: "EDIT_JOURNAL",
  EDIT_LOGIN: "EDIT_LOGIN",
  SET_CAMPAIGN: "SET_CAMPAIGN",
  SET_NEW_CAMPAIGN: "SET_NEW_CAMPAIGN",
  SET_NAME: "SET_NAME",
  CREATE_IMAGE: "CREATE_IMAGE",
  CREATE_MUSIC: "CREATE_MUSIC",
  DELETE_IMAGES: "DELETE_IMAGES",
  DELETE_MUSIC: "DELETE_MUSIC",
  EDIT_INITIATIVE: "EDIT_INITIATIVE"
};

// Location Actions //////////////////////////////////////////

const createLocation = task => ({
  type: Types.CREATE_LOCATION,
  payload: task
});

const deleteLocation = id => ({
  type: Types.DELETE_LOCATION,
  payload: id
});

const editLocation = task => ({
  type: Types.EDIT_LOCATION,
  payload: task
});

const selectLocation = id => ({
  type: Types.SELECT_LOCATION,
  payload: id
});

// Map Actions //////////////////////////////////////////

const createMap = task => ({
  type: Types.CREATE_MAP,
  payload: task
});

const deleteMap = id => ({
  type: Types.DELETE_MAP,
  payload: id
});

const editMap = task => ({
  type: Types.EDIT_MAP,
  payload: task
});

const selectMap = id => ({
  type: Types.SELECT_MAP,
  payload: id
});


// Journal Reducers //////////////////////////////////////////

const createJournal = task => ({
  type: Types.CREATE_JOURNAL,
  payload: task
});

const deleteJournal = id => ({
  type: Types.DELETE_JOURNAL,
  payload: id
});

const editJournal = task => ({
  type: Types.EDIT_JOURNAL,
  payload: task
});

// Auth Reducers //////////////////////////////////////////

const editLogin = boolean => ({
  type: Types.EDIT_LOGIN,
  payload: boolean
});

const setCampaign = task => ({
  type: Types.SET_CAMPAIGN,
  payload: task
});

const setNewCampaign = string => ({
  type: Types.SET_NEW_CAMPAIGN,
  payload: string
});

const createImage = task => ({
  type: Types.CREATE_IMAGE,
  payload: task
});

const createMusic = task => ({
  type: Types.CREATE_MUSIC,
  payload: task
});

const deleteImages = task => ({
  type: Types.DELETE_IMAGES,
  payload: task
});

const deleteMusic = task => ({
  type: Types.DELETE_MUSIC,
  payload: task
});

// Initiative Reducers //////////////////////////////////////////

const editInitiative = task => ({
  type: Types.EDIT_INITIATIVE,
  payload: task
});

export default {
  createLocation,
  deleteLocation,
  editLocation,
  selectLocation,
  createMap,
  deleteMap,
  editMap,
  selectMap,
  createJournal,
  deleteJournal,
  editJournal,
  editLogin,
  setCampaign,
  setNewCampaign,
  createImage,
  createMusic,
  deleteImages,
  deleteMusic,
  editInitiative,
  Types
};