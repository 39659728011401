import ACTIONS from "./action";
import _ from "lodash";

const defaultState = {
  maps: [{name: "Example Map", url: "ch-example-map.jpg", locations: [{description: "This is an example location. Right-click the map to create a new location or right-click an existing pin to delete it.", id: "_692m67xlu", important: null, music: null, name: "Example Pin", position: {x: 21.542227662178703, y: 16.112084063047284}}, {id: "_ggyl2dja7", description: "This is a pin that has been set as 'important'. Use the filter button in the bottom left corner of the map to designate what pins display their name banners.", name: "Important Pin", important: true, music: null, position: {x: 77.96817625458996, y: 63.04728546409807}}], id: "_alkisdjf"}],
  journals: [{id: "_ch-example-journal", name: "Example Journal", description: "Journals are a good place to create notes for yourself, jot down hard-to-remember rules, or enter the stats for recurring NPCs.", image: null}],
  loggedIn: false,
  name: "My Example Campaign"
};

var GenerateID = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

const appReducer = (state = defaultState, action) => {
  switch (action.type) {

    // Map Reducers //////////////////////////////////////////

    case ACTIONS.Types.CREATE_MAP: {
      let item = action.payload;
      let newItem = {id: GenerateID(), url: item.url, name: item.name, locations: []};
      let newState = _.cloneDeep(state);
      newState.maps.push(newItem);
      return newState;
    }

    case ACTIONS.Types.DELETE_MAP: {
      let newState = _.cloneDeep(state);
      let index = _.findIndex(newState.maps, { id: action.payload });
      newState.maps.splice(index, 1);
      return newState;
    }

   case ACTIONS.Types.EDIT_MAP: {
      let item = action.payload;
      let newState = _.cloneDeep(state);
      newState.maps[item.id] = item;
      return newState;
    }

    case ACTIONS.Types.SELECT_MAP: {
      let newState = _.cloneDeep(state);
      let index = _.findIndex(newState.maps, { id: action.payload });
      newState.activeMap = newState.maps[index];

      // Let's grab the actual dimensions of the map for use in the map screen.
      let mapDim = new Image();
      mapDim.src = newState.activeMap.url;
      mapDim.onload = function(){
        newState.activeMap.mapDim = {width: this.width, height: this.height};
      };
      return newState;
    }

    // Location Reducers //////////////////////////////////////////

    case ACTIONS.Types.CREATE_LOCATION: {
      let item = action.payload;
      let newItem = {id: GenerateID(), position: {x: item.position.x, y: item.position.y}, description: item.description, name: item.name, important: item.important, music: item.music};
      let newState = _.cloneDeep(state);
      newState.activeMap.locations.push(newItem);
      return newState;
    }

    case ACTIONS.Types.DELETE_LOCATION: {
      let newState = _.cloneDeep(state);
      let index = _.findIndex(newState.activeMap.locations, { id: action.payload });
      newState.activeMap.locations.splice(index, 1);
      return newState;
    }

    case ACTIONS.Types.EDIT_LOCATION: {
      let item = action.payload;
      let newState = _.cloneDeep(state);
      let index = _.findIndex(newState.activeMap.locations, { id: item.id });
      newState.activeMap.locations[index] = item;
      return newState;
    }

    case ACTIONS.Types.SELECT_LOCATION: {
      let newState = _.cloneDeep(state);

      if (action.payload === null) {
        newState.activeLocation = null;
      } else {
        let index = _.findIndex(newState.activeMap.locations, { id: action.payload });
        newState.activeLocation = newState.activeMap.locations[index];
      }
      return newState;
    }

    // Journal Reducers //////////////////////////////////////////

    case ACTIONS.Types.CREATE_JOURNAL: {
      let item = action.payload;
      let newItem = {id: GenerateID(), description: item.description, name: item.name, images: item.images};
      let newState = _.cloneDeep(state);
      newState.journals.push(newItem);
      return newState;
    }

    case ACTIONS.Types.DELETE_JOURNAL: {
      let newState = _.cloneDeep(state);
      let index = _.findIndex(newState.journals, { id: action.payload });
      newState.journals.splice(index, 1);
      return newState;
    }

    case ACTIONS.Types.EDIT_JOURNAL: {
      let item = action.payload;
      let newState = _.cloneDeep(state);
      newState.journals[item.id] = item;
      return newState;
    }

    // LOGIN ///////////////////////////////////////

    case ACTIONS.Types.EDIT_LOGIN: {
      let newState = _.cloneDeep(state);
      newState.loggedIn = action.payload;
      if (action.payload === false) {
        newState = defaultState;
      }
      return newState;
    }

     // CAMPAIGNS //////////////////////////////////

    case ACTIONS.Types.SET_CAMPAIGN: {
      let item = action.payload;
      let newState = _.cloneDeep(state);
      newState.journals = item.journals;
      newState.maps = item.maps;
      newState.name = item.name;
      newState.campaign = item.campaign;
      newState.initiative = item.initiative;

      if (item.maps.length > 0) {
        newState.activeMap = item.maps[0]
      }

      return newState;
    }

    case ACTIONS.Types.SET_NEW_CAMPAIGN: {
      let newState = _.cloneDeep(state);
      newState.name = action.payload;
      newState.activeMap = newState.maps[0];
      return newState;
    }

     // MEDIA UPLOADER /////////////////////////////

    case ACTIONS.Types.CREATE_IMAGE: {
      let newState = _.cloneDeep(state);
      if (!newState.images) {
        newState.images = [];
      }
      newState.images.push(action.payload);
      return newState;
    }

    case ACTIONS.Types.CREATE_MUSIC: {
      let newState = _.cloneDeep(state);
      if (!newState.music) {
        newState.music = [];
      }
      newState.music.push(action.payload);
      return newState;
    }

    case ACTIONS.Types.DELETE_IMAGES: {
      let newState = _.cloneDeep(state);
      newState.images = [];
      return newState;
    }

    case ACTIONS.Types.DELETE_MUSIC: {
      let newState = _.cloneDeep(state);
      newState.music = [];
      return newState;
    }

    // INITIATIVE /////////////////////////////

    case ACTIONS.Types.EDIT_INITIATIVE: {
      let item = action.payload;
      let newState = _.cloneDeep(state);
      newState.initiative = item;
      return newState;
    }

    default:
      return state;
  }
};

export default appReducer;