import React, { Component } from "react";
import ACTIONS from "../../modules/action";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import fetchFiles from "../../libs/fetchFiles";

class Login extends Component {
    state = {loggedIn: false}

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        // Validation
        if (!this.state.email || !this.state.password) {
            alert("You didn't enter a valid username and password.");
            return;
        }

        try {
            await Auth.signIn(this.state.email, this.state.password);
            this.props.editLogin(true);
            this.getUserS3();
            this.props.history.push("/");
        } catch (e) {
            alert(e.message);
        }
    }

    // Load Media After User Authenticates ///////////////////////////////////////////////////////////

    getUserS3 = () => {
        this.getS3Files('images');
        this.getS3Files('audio');
    }

    getS3Files = async (destination) => {
        // Fetch all images within the user's private folder. The path restrictions are defined in the IAM role for authorized users.
        let privateFiles = await fetchFiles(destination);
        for (var key in privateFiles) {
            if (destination === 'images') {
                this.props.createImage(privateFiles[key]);
            } else {
                this.props.createMusic(privateFiles[key]);
            }
        }
    }

    /////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div className="ch-login-form">
                <div className="video-container">
                    <video width="400" controls>
                        <source src="/tutorial-videos/intro.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <h2>Login</h2>
                    <input placeholder="Enter your email..." type="email" name="email" onChange={this.handleChange} />
                    <input placeholder="Enter your password..." type="password" name="password" onChange={this.handleChange} />
                    <Link to="/reset">Forgot password?</Link>
                    <input className="ch-login-submit" type="submit" value="Login" />
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    editLogin: boolean => dispatch(ACTIONS.editLogin(boolean)),
    createImage: media => dispatch(ACTIONS.createImage(media)),
    createMusic: media => dispatch(ACTIONS.createMusic(media))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);