// Import dependencies
import React, { Component } from 'react';


class TogglePanel extends Component {
    state = {activeTab: this.props.nav[0].content}

    selectNav = (index) => {
        this.setState({
            activeTab: this.props.nav[index].content
        });
    }

    renderNav = () => {
        let navButtons = this.props.nav.map((navButton, index) =>
            <button className={(this.state.activeTab === navButton.content) ? 'active' : 'inactive'} onClick={() => this.selectNav(index)}>{navButton.title}</button>
        );
        return navButtons;
    }

    render () {
        return (
            <div className="ch-togglepanel">
                <div className="ch-togglepanel-nav">
                    {this.renderNav()}
                </div>
                <div className="ch-togglepanel-body">
                    {this.state.activeTab}
                </div>
            </div>
        );
    }
}

export default TogglePanel;
