import React, { Component } from "react";
import ACTIONS from "../../../modules/action";
import Modal from 'react-responsive-modal';
import { connect } from "react-redux";

import MediaDisplay from "../../MediaUploader/MediaDisplay"

class NavigationTabs extends Component {
    state = {open: false, privateImages: null, hasSelectedMedia: false};

    // TAB SELECTION AND DELETION LOGIC ///////////////////////////////////////////

    handleSelect = event => {
        // Change the active map
        this.props.selectMap(event.target.name);
    }

    handleDelete = event => {
        // Prevent the context menu from popping up
        event.preventDefault();
        // Check to make sure the user is serious.
        if (!window.confirm("Are you sure you want to delete this map?")) {
            return;
        }
        // delete the map from the store
        this.props.deleteMap(event.target.name);
        // We will need some code to check if it was the active map and reassign.
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    // MODAL LOGIC ///////////////////////////////////////////

    selectMapMedia = event => {
        this.setState({
            mapUrl: event.target.name,
            hasSelectedMedia: true
        });
    }

    selectGalleryImage = (selection) => {
        this.setState({
            mapUrl: selection.url
        }, () => {console.log(this.state.journalImages)})
    }

    addMedia = () => {
        if (!this.state.hasSelectedMedia && this.props.images) {
            return (<MediaDisplay select="single" handleClick={(e) => this.selectGalleryImage(e)} buttonText={"Select"} gallery={this.props.images} />);
        } else {
            return (<p>{this.state.mapUrl}</p>);
        }
    }

    handleSubmit = () => {

        // validation
        if (!this.state.mapName) {
            alert("Your map requires a name.");
            return;
        } else if (!this.state.mapUrl) {
            alert("Please select a valid image for your map.");
            return;
        }

        // create a new map in the store
        let map = {name: this.state.mapName, url: this.state.mapUrl};
        this.props.createMap(map);
        // Clear out the states so we don't reuse these on accident
        this.setState({
            mapName: null,
            mapUrl: null,
            hasSelectedMedia: false
        });
        this.onMapCloseModal();
    };

    onMapOpenModal = (event) => {
        this.setState({
            open: true
        });
        event.preventDefault();
    }

    onMapCloseModal = () => {
        this.setState({
            open: false
        });
    }



    render() {
        return (
            <div className="ch-navigation-tabs">
                <button onClick={this.onMapOpenModal}>+</button>
                {this.props.maps.map(map => (
                    <button className={(this.props.activeMap === map) ? 'active' : 'inactive'} onClick={this.handleSelect} onContextMenu={this.handleDelete} key={map.id} name={map.id}>{map.name}</button>
                ))}
                <Modal classNames={{modal: {chModal: true}}} open={this.state.open} onClose={this.onMapCloseModal} center>
                    <h2>Create a new map.</h2>
                    <p>Give your map a name:</p>
                    <input autoComplete="off" type="text" name="mapName" onChange={this.handleChange} />
                    <p>Select your map:</p>
                    <div className="ch-media-display-container">
                        {this.addMedia()}
                    </div>
                    <div className="ch-submit-button">
                        <button onClick={this.handleSubmit}>Create Map</button>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
  maps: state.maps,
  activeMap: state.activeMap,
  images: state.images
});

const mapDispatchToProps = dispatch => ({
  deleteMap: id => dispatch(ACTIONS.deleteMap(id)),
  selectMap: id => dispatch(ACTIONS.selectMap(id)),
  createMap: map => dispatch(ACTIONS.createMap(map))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationTabs);