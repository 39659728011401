export default {
  s3: {
    REGION: "us-east-2",
    BUCKET: "ch-eschew-materials"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://wwiewl8m82.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_mPwbyVw0j",
    APP_CLIENT_ID: "542mnjmgrb27s5r55fjmsp18cj",
    IDENTITY_POOL_ID: "us-east-2:5ec8259d-2e26-4e30-bc1f-d8a6a581cd4b"
  }
};