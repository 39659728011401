// Import dependencies
import React, { Component } from 'react';
import { Route } from "react-router-dom";
// Import components
import Home from "./components/Home/Home";
import SelectCampaign from "./components/Home/SelectCampaign";
import Nav from "./components/Nav";
import Logout from "./components/AuthPages/Logout";
import AuthCheck from "./components/AuthPages/AuthCheck";
import Login from "./components/AuthPages/Login";
import ChangePassword from "./components/AuthPages/ChangePassword";
import Register from "./components/AuthPages/Register";
import './App.css';

class App extends Component {
  
  render () {
    return (
      <React.Fragment>
        <Nav />
        <Route path="/register" exact component={Register} />
        <Route path="/reset" exact component={ChangePassword} />
        <Route path="/login" component={Login} />
        <AuthCheck>
          <Route path="/logout" exact component={Logout} />
          <Route path="/" exact component={Home} />
          <Route path="/select-campaign" exact component={SelectCampaign} />
        </AuthCheck>
      </React.Fragment>
    );
  }
}

export default App;
