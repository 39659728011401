import React, { Component } from "react";
import ACTIONS from "../../modules/action";
import Modal from 'react-responsive-modal';
import { connect } from "react-redux";

import fetchFile from "../../libs/fetchFile";
import fetchFiles from "../../libs/fetchFiles";

import MediaDisplay from "../MediaUploader/MediaDisplay"

class LocationDisplayWindow extends Component {
    state = {fetchedLocationAudio: [], open: false, locMusic: null, hasSelectedMedia: false}

    // This is the recommended approach for async http requests.
    componentDidUpdate(prevProps) {
        if (this.props.activeLocation && (!prevProps.activeLocation || this.props.activeLocation.music !== prevProps.activeLocation.music)) {
            // Wipe our fetched link if this location doesn't have music.
            this.setState({fetchedLocationAudio: []});
            if (this.props.activeLocation.music) {
                for (let i = 0; i < this.props.activeLocation.music.length; i++) {
                    console.log(this.props.activeLocation.music[0]);
                    this.getLocationAudio(this.props.activeLocation.music[i].url);
                }
            }
        }
    }

    // LOCATION DISPLAY LOGIC ////////////////////////////////////////

    getLocationAudio = async (key) => {
        let fetchedLocationAudio = await fetchFile(key, 'private');
        this.setState({
            fetchedLocationAudio: this.state.fetchedLocationAudio.concat(fetchedLocationAudio)
        });
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    // MODAL LOGIC ////////////////////////////////////////

    onOpenModal = event => {
        this.setState({
            open: true,
            locName: this.props.activeLocation.name,
            locDescription: this.props.activeLocation.description,
            locImportant: this.props.activeLocation.important
        });

        // Verify that there is music before copying it.
        if (this.props.activeLocation.music) {
            this.setState({
                locMusic: this.props.activeLocation.music
            });
        }

        event.preventDefault();
    }

    onCloseModal = () => {
        this.setState({
            open: false
        });
    }

    handleChecked = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    }

    handleSubmit = () => {

        // validation
        if (!this.state.locName) {
            alert("Your location requires a name.");
            return;
        }

        // replace the location in the store
        let location = {name: this.state.locName, position: this.props.activeLocation.position, description: this.state.locDescription, important: this.state.locImportant, music: this.state.locMusic, id: this.props.activeLocation.id};
        this.props.editLocation(location);

        // reselect the location from the store so we have updated info.
        this.props.selectLocation(location.id);
        
        // Clear out the states so we don't reuse these on accident
        this.setState({
            locName: null,
            locPosition: null,
            locDescription: null,
            locImportant: null,
            locMusicName: null,
            locMusicSrc: null,
            locMusic: null
        });
        this.onCloseModal();
    };

    // MODAL AUDIO LOGIC ////////////////////////////////////////

    getS3Audio = async event => {
        let privateTracks = await fetchFiles('audio');

        // If there is no media, shout that out.
        if (privateTracks.length < 1) {
            this.setState({
                noMedia: true
            });
        }

        this.setState({
            privateAudio: privateTracks
        });
    }

    selectGalleryAudio = (selections) => {

        let selectedMedia = [];

        for (let i = 0; i < selections.length; i++) { 
            selectedMedia.push(selections[i]);
        }

        this.setState({
            locMusic: selectedMedia
            // locMusicSrc: selection,
            // locMusicName: selection.split('/')[1],
            //hasSelectedMedia: true
        }, () => {console.log("My Test: " + this.state.locMusic)})
    }

    addMedia = () => {
        if (!this.state.hasSelectedMedia && this.props.music) {
            return (<MediaDisplay select="multiple" audio={true} handleClick={(e) => this.selectGalleryAudio(e)} buttonText="Select" gallery={this.props.music} />);
        } else {
            return (<p>{this.state.locMusicName}</p>);
        }
    }


    render() {
        if (this.props.activeLocation) {
            return (
                <div className="ch-location-window">
                    <h2>Current Location</h2>
                    <div className="ch-location-content">
                        <h3>{this.props.activeLocation.name}</h3>
                        <p>{this.props.activeLocation.description}</p>
                        {this.props.activeLocation.music && this.props.activeLocation.music.map((track, index) =>
                            <div className="ch-audio-container" key={track.name}>
                                <p>Track: <em>{track.name}</em></p>
                                <audio className="ch-audio-player" src={this.state.fetchedLocationAudio[index]} controls loop />
                            </div>
                        )}
                        <button className="ch-edit-button" onClick={this.onOpenModal}>Edit this location.</button>
                    </div>
                    <Modal classNames={{modal: {chModal: true}}} open={this.state.open} onClose={this.onCloseModal} center>
                        <h2>What do you want to change?</h2>
                        <p>Change the name:</p>
                        <input autoComplete="off" type="text" defaultValue={this.props.activeLocation.name} name="locName" onChange={this.handleChange} />
                        <p>Change the description:</p>
                        <textarea name="locDescription" defaultValue={this.props.activeLocation.description} onChange={this.handleChange} />
                        <p>Select music for this location:</p>
                        <div className="ch-media-display-container">
                            {this.addMedia()}
                        </div>
                        <div className="ch-important-input">
                            <label>Important?</label>
                            <input type="checkbox" name="locImportant" defaultChecked={this.props.activeLocation.important} onChange={this.handleChecked} />
                        </div>
                        <div className="ch-submit-button">
                            <button onClick={this.handleSubmit}>Update Location</button>
                        </div>
                    </Modal>
                </div>
            );
        } else {
            return (
                <div className="ch-location-window">
                    {this.props.activeMap && <h2>Please select a location from the map.</h2>}
                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    activeLocation: state.activeLocation,
    activeMap: state.activeMap,
    music: state.music
});

const mapDispatchToProps = dispatch => ({
    editLocation: task => dispatch(ACTIONS.editLocation(task)),
    selectLocation: task => dispatch(ACTIONS.selectLocation(task))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationDisplayWindow);