import React, { Component } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { connect } from "react-redux";
import Modal from 'react-responsive-modal';
import ACTIONS from "../modules/action";
import { API } from "aws-amplify";

import Tutorials from "./Tutorials";
import MediaUploader from "./MediaUploader/MediaUploader";

class Nav extends Component {
    state = {open: false, openMedia: false, hasWarned: false, showMobileDropdown: false}

    showMobileDropdown = () => {
        this.setState({showMobileDropdown: !this.state.showMobileDropdown});
    }

     // TUTORIALS LOGIC ///////////////////////////////////////////

    onOpenModal = () => {
        this.setState({open: true});
    }

    onCloseModal = () => {
        this.setState({open: false});
    }

    // MEDIA UPLOADER LOGIC ///////////////////////////////////////////

    onOpenMediaModal = () => {
        this.setState({openMedia: true});
    }

    onCloseMediaModal = () => {
        this.setState({openMedia: false});
    }

    // SAVE/LOAD/DELETE CAMPAIGN LOGIC ///////////////////////////////////////////

    searchMatchingCampaign = async event => {
        let campaigns = await this.getCampaigns();

		let matchFound = false;
        for (var i in campaigns) {
            if (campaigns[i].campaign === this.props.campaign) {
                // We are overwriting an existing campaign.
				matchFound = true;
           } else {
               // We are working with a brand new campaign.
			    console.log("No match found.");
           }
        }
        return matchFound;
    }
    
    saveCampaign = async event => {
        // Check if the current campaign ID exists in the store
        let overwrite = await this.searchMatchingCampaign();

        console.log(this.props.state);

        if (overwrite) {
            await API.put("campaigns", "/campaigns/" + this.props.campaign, {
                body: this.props.state
            });
            alert("Your campaign has been saved.");
        } else {
            alert("There was an error in saving your campaign.");
        }
    }

    deleteCampaign = async event => {
        // Check if the current campaign ID exists in the store
        let overwrite = await this.searchMatchingCampaign();

        if (overwrite) {

            // Verify that the users actually want to delete this.
            if (!window.confirm("Are you sure that you want to delete this campaign? The deletion is permanent.")) {
                return;
            }

            await API.del("campaigns", "/campaigns/" + this.props.campaign);
            alert("This campaign has been deleted.");

            // Logout the user.
            this.props.editLogin(false);

        } else {
            alert("There was an error deleting your campaign.");
        }

    }

    getCampaigns = () => {
        return API.get("campaigns", "/campaigns");
    }

    campaignSave = () => {
        if (this.props.campaign) {
            return (<div className="ch-navbar-left"><button onClick={this.saveCampaign}>Save Campaign</button><button onClick={this.deleteCampaign}>DELETE campaign</button></div>);
        } else {
            return (<div className="ch-navbar-left"><div className="ch-hello">Welcome to Eschew Notes!</div></div>);
        }
    }

    render() {
        if (!this.props.loggedIn) {
            return (
                <div className="ch-navbar">
                    <div className="ch-navbar-container">
                        <Link to="/login">Login</Link>
                        <Link to="/register">Register</Link>
                    </div>
                </div>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="ch-navbar">
                        {this.campaignSave()}
                        <div className="ch-navbar-container">
                            <Link to="/">Home</Link>
                            <button onClick={this.onOpenMediaModal}>Media Uploader</button>
                            <button onClick={this.onOpenModal}>Tutorials</button>
                            <Link to="/logout">Logout</Link>
                        </div>
                    </div>
                    <div className="ch-mobile-navbar">
                        <div className="ch-navbar-container">
                            <button className="ch-hamburger-icon" onClick={this.showMobileDropdown}>&#9776;</button>
                            {this.state.showMobileDropdown && <div className="ch-mobile-dropdown">
                                {this.campaignSave()}
                                <Link to="/">Home</Link>
                                <button onClick={this.onOpenMediaModal}>Media Uploader</button>
                                <button onClick={this.onOpenModal}>Tutorials</button>
                                <Link to="/logout">Logout</Link>
                            </div>
                            }
                        </div>
                    </div>
                    <Modal classNames={{modal: {chModal: true}}} showCloseIcon={false} open={this.state.open} onClose={this.onCloseModal} center>
                        <Tutorials />
                    </Modal>
                    <Modal classNames={{modal: {chModal: true}}} showCloseIcon={false} open={this.state.openMedia} onClose={this.onCloseMediaModal} center>
                        <MediaUploader />
                    </Modal>
                </React.Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    loggedIn: state.loggedIn,
    campaign: state.campaign,
    state: state
});

const mapDispatchToProps = dispatch => ({
  editLogin: bool => dispatch(ACTIONS.editLogin(bool)),
  setCampaign: task => dispatch(ACTIONS.setCampaign(task))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Nav);