import React, { Component }  from 'react';
import JournalSearchWindow from "./JournalSearchWindow";
import LocationDisplayWindow from "./LocationDisplayWindow";
import NavigationTabs from "./MapWindow/NavigationTabs";
import MapWindow from "./MapWindow/MapWindow";
import TogglePanel from "../TogglePanel";
import InitiativeTracker from "./InitiativeTracker/InitiativeTracker";
import Generators from "./Generators/Generators";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Home extends Component {

    state = {};

    render() {
        if (!this.props.campaign) {
            return (
                // If the user hasn't selected a campaign yet, let's redirect them.
                <Redirect to="/select-campaign" />
            );
        } else {
      return (
            <div className="app">
                <div className="app-column ch-width-60">
                    <NavigationTabs />
                    <MapWindow />
                </div>
                <div className="app-column ch-width-40">
                    <TogglePanel nav={[{content: <JournalSearchWindow />, title: "Journals"}, {content: <InitiativeTracker />, title: "Initiative Tracker"}, {content: <Generators />, title: "Generators"}]} />
                    {/*<JournalSearchWindow />*/}
                    <LocationDisplayWindow />
                </div>
            </div>
            
        );
        }
    }
}

const mapStateToProps = state => ({
    campaign: state.campaign
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
