import React, { Component } from "react";
import { connect } from "react-redux";

class MediaDisplay extends Component {
    state = {selectedMedia: []}

    renderImages = () => {

        // Don't blow up if we don't have images.
        if (!this.props.gallery || this.props.gallery.length === 0) {
            return null;
        }

        let images = this.props.gallery.map((galleryImage) =>
                        <div className={"ch-media-display-item " + (this.state.selectedMedia.filter(e => e.url === galleryImage.name).length > 0 ? "selected" : "unselected")} key={galleryImage.name} style={{backgroundImage: 'url(' + galleryImage.url + ')'}}>
                            <div className="overlay">
                                <button className="ch-media-select-button ch-purple-button" name={galleryImage.name} onClick={this.handleClick}>{this.props.buttonText}</button>
                            </div>
                        </div>
                    );
        return images;
    }

    renderAudio = () => {

        // Don't blow up if we don't have music tracks.
        if (!this.props.gallery || this.props.gallery.length === 0) {
            return null;
        }

        let tracks = this.props.gallery.map((galleryTrack) =>
                        <div key={galleryTrack.name} className={"ch-media-display-item ch-music-item " + (this.state.selectedMedia.filter(e => e.url === galleryTrack.name).length > 0 ? "selected" : "unselected")}>
                            <p>{galleryTrack.name.split('/')[1]}</p>
                            <audio className="ch-audio-player" src={galleryTrack.url} controls loop />
                            <button className="ch-purple-button" name={galleryTrack.name} onClick={this.handleClick}>{this.props.buttonText}</button>
                        </div>
                    );
        return tracks;
    }

    handleClick = (event) => {
        switch (this.props.select) {
            case "single":
            default:
                this.setState(
                    {selectedMedia: [{url: event.target.name, name: event.target.name.split('/')[1]}]},
                    () => {
                        this.props.handleClick(this.state.selectedMedia[0]);
                    }
                );
                break;
            case "multiple":
                if (this.state.selectedMedia.length > 3) {
                    alert("You can only have four music tracks assigned to one location!");
                    return;
                }
                let selectedMedia = this.state.selectedMedia.concat({url: event.target.name, name: event.target.name.split('/')[1]});
                this.setState(
                    {selectedMedia: selectedMedia},
                    () => {
                        this.props.handleClick(this.state.selectedMedia);
                    }
                );
                break;
        }

    }

    render() {
       return (
           <div className="ch-media-display">
                {this.props.audio ? this.renderAudio() : this.renderImages()}
           </div>
       );
    }
}

const mapStateToProps = state => ({
    media: state.media
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaDisplay);