import React, { Component }  from 'react';
import ACTIONS from "../../modules/action";
import { connect } from "react-redux";
import { API } from "aws-amplify";

class SelectCampaign extends Component {

    state = {userCampaigns:null, campaignButtons: null};

    componentDidMount = () => {
        // Let's grab a list of the user's campaigns.
        this.getCampaigns();
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    newCampaign = async event => {
        //validation
        if (this.state.userCampaigns.length > 3) {
            alert("Sorry, you can't make more than four campaigns at this time.");
            return;
        }

        if (!this.state.campaignName) {
            alert("Please enter a name for your campaign.");
            return;
        }
        await this.props.setNewCampaign(this.state.campaignName);
        
        await API.post("campaigns", "/campaigns", {
                        body: this.props.state
            }).then(response => {
                // Let's replace our state with the newly generated campaign make sure everything lines up.
                this.props.setCampaign(response);
            });
        
        this.props.history.push("/");
    }

    selectCampaign = (e) => {
        // Grab the right campaign using its index.
        let campaign = this.state.userCampaigns[e.target.name];
        // Repace our current Redux state with the one we grabbed from the API
        this.props.setCampaign(campaign);
        this.props.history.push("/");
    }

    getCampaigns = async event => {
        let userCampaigns = null;
        let campaignButtons = null;
        try {
            userCampaigns = await API.get("campaigns", "/campaigns");
            // If we have existing campaigns, lets map them out and render them as a clickable list.
            if (userCampaigns.length > 0) {
                campaignButtons = userCampaigns.map((campaign, id) =>
                    <button onClick={this.selectCampaign} name={id} key={id}>
                        {campaign.name}
                    </button>
                );
            }
        } catch (e) {
            alert(e);
        }
        this.setState({
            userCampaigns: userCampaigns,
            campaignButtons: campaignButtons
        });
    }

    render() {
       return (
            <div className="app ch-campaign-selection">
                <h2>Start a brand new campaign.</h2>
                <input name="campaignName" onChange={this.handleChange} />
                <button onClick={this.newCampaign}>Create a new campaign.</button>
                <br/>
                {this.state.campaignButtons &&
                    <div className="ch-gray-panel">
                        <h1>Select an existing campaign:</h1>
                        {this.state.campaignButtons}
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    campaign: state.campaign,
    state: state
});

const mapDispatchToProps = dispatch => ({
    setCampaign: task => dispatch(ACTIONS.setCampaign(task)),
    setNewCampaign: string => dispatch(ACTIONS.setNewCampaign(string))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectCampaign);
