import React, { Component } from "react";
import ACTIONS from "../../modules/action";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { Redirect, Route } from "react-router-dom";
import fetchFiles from "../../libs/fetchFiles";

class AuthCheck extends Component {
    state = {isAuthenticating: true}

    async componentDidMount() {
        try {
            await Auth.currentSession();
            this.props.editLogin(true);
            this.getUserS3();
        }
        catch(e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        this.setState({ isAuthenticating: false });
    }

    forceRedirect = () => {
        if (this.props.loggedIn) {
            return (
                this.props.children
            );
        } else {
            return (
                <Route path="/" exact render={() => <Redirect to="/login" />} />
            )
        }
    }

    // Load Media After User Authenticates ///////////////////////////////////////////////////////////

    getUserS3 = () => {
        this.getS3Files('images');
        this.getS3Files('audio');
    }

    getS3Files = async (destination) => {
        // Fetch all images within the user's private folder. The path restrictions are defined in the IAM role for authorized users.
        let privateFiles = await fetchFiles(destination);
        for (var key in privateFiles) {
            if (destination === 'images') {
                this.props.createImage(privateFiles[key]);
            } else {
                this.props.createMusic(privateFiles[key]);
            }
        }
    }

    ///////////////////////////////////////////////////////////////

    render() {
        return (
            !this.state.isAuthenticating &&
            this.forceRedirect()
        )
        
    }
}

const mapStateToProps = state => ({
    loggedIn: state.loggedIn
});

const mapDispatchToProps = dispatch => ({
    editLogin: boolean => dispatch(ACTIONS.editLogin(boolean)),
    createImage: media => dispatch(ACTIONS.createImage(media)),
    createMusic: media => dispatch(ACTIONS.createMusic(media))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthCheck);