// Import dependencies
import React, { Component } from 'react';
import { connect } from "react-redux";
import ACTIONS from "../../../modules/action";


class Generators extends Component {
    state = {generatorValue: "", generators: [], nameInput: ""};

    componentDidMount = () => {
        let localGenerators = JSON.parse(localStorage.getItem("generators"));
        if (localGenerators) {
            this.setState({generators: localGenerators});
        }
    }

    handleChange = (event) => {
        this.setState({generatorValue: event.target.value});
    }

    handleNameChange = (event) => {
        this.setState({nameInput: event.target.value});
    }

    renderGenerators = () => {
        let generators = this.state.generators.map((generator, index) =>
            <div className="ch-generator">
                <button className="ch-generator-delete" name={index} onClick={this.deleteGenerator}>X</button>
                <button className="ch-generator-button" name={index} onClick={this.runGenerator}>{generator.name} &#8680;</button>
                <p className="ch-generator-results">{generator.result}</p>
            </div>
        );
        
        return generators;
    }

    deleteGenerator = (event) => {
        let cloneGenerators = this.state.generators.slice();
        cloneGenerators.splice(event.target.name, 1);
        this.setState({generators: cloneGenerators});
        this.saveToLocal();
    }

    runGenerator = (event) => {
        let cloneGenerators = this.state.generators.slice();
        cloneGenerators[event.target.name].result = cloneGenerators[event.target.name].code[Math.floor(Math.random()*cloneGenerators[event.target.name].code.length)];
        this.setState({generators: cloneGenerators});
    }

    handleSubmit = () => {
        let cloneGenerators = this.state.generators.slice();
        cloneGenerators.push({name: this.state.nameInput, code: this.state.generatorValue.split(/\r?\n/), result: ""})
        this.setState({generators: cloneGenerators, nameInput: "", generatorValue: ""});
        this.saveToLocal();
    }


    saveToLocal = () => {
        localStorage.setItem("generators", JSON.stringify(this.state.generators));
    }

    render () {
        return (
            <div className="ch-generators">
                <p><b>Enter your generator below.</b></p>
                <label>Name<br/>
                    <input onChange={this.handleNameChange} value={this.state.nameInput} />
                </label>
                <label>Code
                    <textarea name="generator" onChange={this.handleChange} value={this.state.generatorValue} />
                </label>
                <button onClick={this.handleSubmit}>Create Generator</button>
                <hr/>
                {this.renderGenerators()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    initiative: state.initiative
});

const mapDispatchToProps = dispatch => ({
    editInitiative: task => dispatch(ACTIONS.editInitiative(task))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Generators);
