// Import dependencies
import React, { Component } from 'react';


class Tutorials extends Component {
  
  render () {
    return (
        <div className="ch-tutorial">
            <h2 tabIndex="0">Tutorials</h2>
            <p>Thanks for checking out Eschew Note. Let me give you a quick rundown of the features.</p>
            <h3>So what is Eschew Note?</h3>
            <p>Eschew Note is a web app that helps you run your favorite tabletop roleplaying games. It should be fairly system agnostic (but I'm a big Pathfinder fan).</p>
            <h3>How do I add maps and locations?</h3>
            <p>To add a new map, click on the "+" button in the top left corner. A pop-up will walk you through the rest.</p>
            <p>To create a new location, right click on a map.</p>
            <h4>Edit and delete locations.</h4>
            <p>To edit a location, select it by left-clicking the pin and then look for the "edit location" button in the right-hand display.</p>
            <p>To delete a location, right-click the location's pin. An alert will pop-up to confirm your deletion.</p>
            <h4>Delete an entire map.</h4>
            <p>Careful! Deleting a map deletes all of its locations as well. If that's what you want, look at the tabs in the top left of the screen and find the one with your map's name. Right-click the tab to delete.</p>
            <h3>What are journals?</h3>
            <p>Journals are your personal database of notes. I find it cumbersome to search through long articles on wikis for specific rules or names, so if you have a specific rule or character you'll need to reference during your game - just enter it as a journal entry! NPCs, combat rules, nations, and random encounter tables are all good fodder for Journals.</p>
            <h4>How do I delete a journal?</h4>
            <p>Select the journal in question from the dropdown and look for a "delete this journal" button in the right-hand display.</p>
            <h3>How do I upload more music/images?</h3>
            <p>Navigate over to the "media uploader" page. Careful! You need to save your work first or it will be lost. You can upload .png, .jpg, .mp3, or .wav files of 2mb or less. I haven't scaled up my hosting yet, so you can't upload more than 40 files at this time.</p>
            <h3>What's coming in the next update?</h3>
            <p>EN is in super-active development right, so there should be plenty of updates. Here's some stuff I'm excited about right now:</p>
            <ul>
                <li>Add multiple images to journals and multiple music tracks to locations.</li>
                <li>Maintain "zoom" on maps when navigating between them.</li>
                <li>Add an initiative tracker.</li>
                <li>Cast a "player-friendly" map to your players.</li>
            </ul>
            <div className="ch-social">I'm open to any ideas you guys might have, so hit me up on your choice of social media. I'm fairly active on <a rel="noopener noreferrer" href="https://www.reddit.com/user/abilledeaux" target="_blank">reddit</a> and I've got a brand new <a rel="noopener noreferrer" href="https://www.facebook.com/Cold-Harbor-Games-870504379959268/" target="_blank">Facebook Page</a>.</div>
            <p>A big thanks to Dyson's Dodecahedron: I'm using their free-for-commercial-use map as an example map. You can find their stuff <a rel="noopener noreferrer" href="https://dysonlogos.blog/" target="_blank">here</a>.</p>
        </div>
    );
  }
}

export default Tutorials;
