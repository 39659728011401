import React, { Component } from "react";
import ACTIONS from "../../modules/action";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";

class Logout extends Component {

    async componentDidMount() {
        await Auth.signOut();

        this.props.editLogin(false);
    }

    render() {
        return (
            <Redirect to="/login" />
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    editLogin: boolean => dispatch(ACTIONS.editLogin(boolean))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout);